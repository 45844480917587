var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { col: "12" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c(
                    "CRow",
                    [
                      _c("CCol", { staticClass: "card-header-with-button" }, [
                        _vm._v("Sites")
                      ]),
                      _c(
                        "CCol",
                        { staticClass: "text-right" },
                        [
                          _vm.permissionsData.edit_site
                            ? _c(
                                "CButton",
                                {
                                  attrs: { color: "success" },
                                  on: { click: _vm.addSiteClick }
                                },
                                [_vm._v("Add Site")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCardBody",
                [
                  _vm.showTable
                    ? _c("CDataTable", {
                        attrs: {
                          hover: "",
                          striped: "",
                          items: _vm.sitesData,
                          fields: _vm.fields,
                          "items-per-page": 15,
                          "clickable-rows": "",
                          "active-page": _vm.activePage,
                          pagination: { doubleArrows: false, align: "center" }
                        },
                        on: {
                          "row-clicked": _vm.rowClicked,
                          "page-change": _vm.pageChange
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }